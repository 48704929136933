.Navbar {
  @apply bg-neutral-50 sticky lg:relative top-0 left-0 z-50;
  @apply lg:shadow-soft;
  @apply dark:bg-night-50;

  &__Container {
    @apply container mx-auto flex justify-between items-center py-4 px-8;
    @apply lg:py-5 lg:px-16;
  }

  &__Hotlink {
    @apply cursor-pointer;
  }

  &__Logo {
    @apply w-16;
  }

  &__Nav {
    @apply w-full h-[calc(var(--full-height)-84px)] max-h-0 overflow-y-hidden absolute top-full left-0 grid grid-rows-[1fr_auto] gap-x-8 bg-neutral-50 px-8;
    @apply lg:w-fit lg:h-fit lg:max-h-fit lg:overflow-visible lg:static lg:grid-cols-[repeat(3,_auto)] lg:items-center lg:p-0;
    @apply dark:bg-night-50;

    &--Visible {
      @apply max-h-fit pt-16 pb-8 transition-all;
    }
  }

  &__List {
    @apply w-fit h-fit;
    @apply lg:flex lg:items-center;
  }

  &__Item {
    @apply mb-4 last-of-type:m-0;
    @apply lg:mb-0 lg:mr-4 lg:last-of-type:m-0;
  }

  &__Link {
    @apply text-2xl font-semibold text-neutral-900 transition-all duration-200 transform;
    @apply lg:text-base lg:font-medium;
    @apply dark:text-neutral-50;
    @apply hover:text-primary dark:hover:text-primary;
  }

  &__Button {
    @apply hidden;
    @apply lg:block;
  }

  &__Actions {
    @apply flex;
    @apply lg:border-l-2 lg:pl-4 lg:border-neutral-900;
    @apply dark:border-neutral-50;
  }

  &__ActionLanguage {
    @apply relative mr-4;
  }

  &__Icon {
    @apply w-7 h-7 cursor-pointer transition-all duration-200 transform;
    @apply dark:stroke-neutral-50;
    @apply hover:stroke-primary;
  }

  &__Tooltip {
    @apply absolute left-0 bottom-[calc(100%+1.5rem)] bg-neutral-50 shadow-soft w-fit max-h-0 overflow-hidden;
    @apply lg:left-auto lg:bottom-auto lg:top-[calc(100%+2rem)] lg:right-0;
    @apply dark:bg-night-100 lg:dark:bg-night-50 dark:text-neutral-50;

    &--Visible {
      @apply max-h-fit py-2 px-6;
    }
  }

  &__TooltipButton {
    @apply mr-4 mb-2 text-xl transform;
    @apply lg:mr-2 lg:mb-1;
    @apply hover:text-primary;
  }

  &__OffcanvasButton {
    @apply lg:hidden;
  }

  &__OffcanvasIcon {
    @apply w-9 h-9 cursor-pointer;
  }
}
