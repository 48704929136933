:root {
  --full-height: 100vh;
  --loader-background: #fff;
  --loader-height: 100vh;
  --loader-icon-top: 0;
  --loader-icon-accent: #000;
}

body {
  @apply font-sans bg-white text-neutral-900;
  @apply dark:bg-night-100 dark:text-neutral-50;
}
